<template>
  <div>
    <div class="sticky">
      <v-container>
        <v-card>
          <v-container>
            <v-progress-linear :value="step * 25"></v-progress-linear>
            Step {{ step }}/4
          </v-container>
        </v-card>
      </v-container>
    </div>
    <div>
      <v-form lazy-validation ref="form" v-model="validForm1">
        <div v-if="step == 1">
          <v-container>
            <h4>Select Gender</h4>
            <v-radio-group v-model="obj.gender" row>
              <v-radio label="Male" value="Male"></v-radio>
              <v-radio label="Female" value="Female"></v-radio>
            </v-radio-group>
            <h4>
              <strong> Marital Status</strong>
            </h4>
            <v-radio-group v-model="obj.maritalStatus" row>
              <v-radio label="Married" value="Married"></v-radio>
              <v-radio label="Single" value="Single"></v-radio>
            </v-radio-group>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="obj.firstName"
                  :rules="firstname"
                  hint="First name as per your PAN card"
                  label="First Name"
                  outlined
                  type="string"
                  required
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="obj.lastName"
                  :rules="lastname"
                  hint="Last name as per your PAN card"
                  label="Last Name"
                  outlined
                  type="string"
                />
              </v-col>
            </v-row>

            <div>
              <strong>Date of Birth</strong>
              <span class="caption my-auto ml-1">(Above 18 Years)</span>
            </div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="obj.dateOfBirth"
                  :rules="dob"
                  hint="DOB as per your PAN card"
                  outlined
                  placeholder="dd/mm/yyyy"
                  :max="maxDateOfBirth"
                  type="date"
                />
              </v-col>
            </v-row>
            <v-alert outlined color="info" v-if="coapplicantRequired">
              <p class="caption ma-0 pa-0">
                Mandatory co-applicant for age b/w <strong>18</strong> -
                <strong>21</strong> years
              </p>
            </v-alert>
            <v-container class="text-center mt-10">
              <div class="d-inline-block">
                <v-btn color="teal" plain @click="back" :disabled="step == 1"
                  >Back</v-btn
                >
                <v-btn color="primary" @click="nextForm('form', false)"
                  >Next</v-btn
                >
              </div>
            </v-container>
          </v-container>
        </div>
      </v-form>
      <!-- form1 ends -->
      <v-form lazy-validation ref="form3" v-model="validForm3">
        <div v-if="step == 3">
          <v-container>
            <div>
              <strong>Employment Status</strong>
            </div>
            <v-select
              v-model="employmentType"
              :items="employmentTypeList"
              item-text="type"
              item-value="type"
              outlined
            ></v-select>
            <div>
              <strong>Employer/Organisation/Company</strong>
            </div>
            <v-text-field
              v-model="employerName"
              :rules="employer"
              class="salaried"
              hint="Enter your company name here"
              outlined
              placeholder="eg. Ontrack Technologies Pvt Ltd."
              type="text"
            />
            <div>
              <strong
                >Current Annual Income
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon small>fal fa-info-circle </v-icon>
                    </span>
                  </template>
                  <span>As per last financial year in INR</span>
                </v-tooltip>
              </strong>
            </div>
            <v-text-field
              v-model="annualGrossSalary"
              :rules="income"
              hint="This is only used to calculate eligible loan amount only."
              outlined
              prefix="₹"
              required
              type="currency"
              maxlength="12"
            />
            <div>
              <strong>Select a Document for Verification</strong>
            </div>
            <v-slide-group class="my-4" mandatory show-arrows>
              <v-slide-item v-for="n in documents" :key="n.type">
                <v-btn
                  :color="
                    selectedDocument === n.type ? 'primary white--text' : ''
                  "
                  :input-value="n.type"
                  class="mx-2"
                  depressed
                  rounded
                  @click="selectedDocument = n.type"
                >
                  {{ n.text }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>

            <v-text-field
              :key="selectedDocument"
              :rules="docrules"
              v-model="documentValue"
              :placeholder="selectedDocument + ' Number'"
              append-icon="fal fa-check"
              hint="This is required for KYC verification."
              outlined
            />
          </v-container>
          <v-container class="text-center pt-10">
            <div class="d-inline-block">
              <v-btn text color="teal" plain @click="back">Back</v-btn>
              <v-btn color="primary" @click="nextForm('form3')">Next</v-btn>
            </div>
          </v-container>
        </div>
      </v-form>
      <v-form lazy-validation ref="form2" v-model="validForm2">
        <div v-if="step == 2">
          <!--      address proof section contiainer-->
          <v-container>
            <v-select
              v-model="addressType"
              :items="addressTypeList"
              item-text="state"
              item-value="abbr"
              outlined
              label="Address proof"
            ></v-select>
            <v-select
              v-model="propertyStatus"
              :items="propertyStatusList"
              item-text="state"
              item-value="abbr"
              outlined
              label="Current Residential Status"
            ></v-select>
            <div class="my-1"><strong>Address</strong></div>
            <v-text-field
              v-model="addressOneInput"
              :rules="addressOne"
              label="Flat Number/ Building name/Society"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="addressTwoInput"
              :rules="addressTwo"
              label=" Street/Locality/Area"
              outlined
            ></v-text-field>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="pincode.pincode"
                  append-icon="fal fa-check"
                  filledad
                  label="Pincode"
                  outlined
                  readonly
                />
              </v-col>
              <v-col cols="7">
                <v-text-field
                  append-icon="fal fa-check"
                  filled
                  label="City"
                  outlined
                  readonly
                  value="Bangalore"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container class="text-center pt-10">
            <div class="d-inline-block">
              <v-btn text color="teal" plain @click="back">Back</v-btn>
              <v-btn color="primary" @click="nextForm('form2')">Next</v-btn>
            </div>
          </v-container>
        </div>
      </v-form>
      <v-form lazy-validation ref="form4" v-model="validForm4">
        <div v-if="step == 4">
          <v-container>
            <v-text-field
              v-model="obj.emailId"
              :rules="emailRules"
              outlined
              placeholder="mail@gmail.com"
              label="Email"
            ></v-text-field>

            <v-text-field
              v-model="obj.mobileNumber"
              :rules="mobilenumber"
              clearable
              hint=""
              label="Mobile Number"
              outlined
              counter
              type="text"
              maxlength="10"
              prefix="+91"
            ></v-text-field>
            <v-container class="text-center pt-10">
              <div class="d-inline-block">
                <v-btn
                  text
                  color="teal"
                  plain
                  @click="back"
                  :disabled="step == 1"
                  >Back</v-btn
                >
                <v-btn
                  class="white--text"
                  color="primary"
                  @click.prevent="nextForm('form4', true)"
                >
                  Proceed
                  <v-icon class="my-0 py-0">fal fa-angle-right</v-icon>
                </v-btn>
              </div>
            </v-container>
          </v-container>
        </div>
      </v-form>
    </div>
    <v-dialog v-model="otpSent" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 ma-0 px-3">
          Verify OTP
        </v-card-title>
        <div class="pa-4">
          <p>Please enter OTP sent to {{ obj.mobileNumber }}</p>
          <v-text-field autofocus type="number" v-model="otp"></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="otpSent = false">
            Change Phone
          </v-btn>
          <v-btn color="green darken-1" text @click="verifyOTP">
            Verify Otp
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="formFound"
      persistent
      max-width="290"
      v-if="formToPopulate && formFound"
    >
      <v-card>
        <v-card-title class="text-h5">
          Prefill Data
        </v-card-title>
        <v-card-text
          >We found a form previously filled by you
          {{ formToPopulate.firstName }} with address
          {{ formToPopulate.address[0].addressLine1 }}. <br />
          Do you wish to populate?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="formFound = false">
            I'll fill Fresh
          </v-btn>
          <v-btn color="green darken-1" text @click="populateData">
            Populate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="otpVerificationFailed">
      OTP Verification Failed.
    </v-snackbar>
  </div>
  <!--    main container close-->
</template>
<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  noSpecialChars,
  onlyDigits,
} from "@/modules/finance/validators/noSpecialChars";

export default {
  mixins: [validationMixin],
  validations: {
    employerName: {
      required,
      noSpecialChars,
    },
    annualGrossSalary: {
      required,
      onlyDigits,
    },
    firstname: {
      required,
    },
    lastname: {
      required,
    },
  },

  data: () => ({
    otpVerificationFailed: false,
    invalidFeedbackAlert: false,
    step: 1,
    snackbar: false,
    validForm1: false,
    validForm2: false,
    validForm3: false,
    validForm4: false,
    genderDataItems: [
      {
        gender: "Male",
        icon: "fal fa-male",
      },
      {
        gender: "Female",
        icon: "fal fa-female",
      },
    ],

    propertyStatusList: [
      {
        state: "Own house",
        abbr: "O",
      },
      {
        state: "Company Provided",
        abbr: "C",
      },
      {
        state: "Mortgaged",
        abbr: "M",
      },
      {
        state: "Relatives House",
        abbr: "H",
      },
      {
        state: "Rented",
        abbr: "R",
      },
    ],
    addressTypeList: [
      {
        state: "Residence Address",
        abbr: "RESIDENCE ADDRESS",
      },
      {
        state: "Office Address",
        abbr: "OFFICE ADDRESS",
      },
      {
        state: "Permanent Address",
        abbr: "PERMANENT ADDRESS",
      },
    ],
    takeConsent: true,
    otpVerified: false,
    otpSent: false,
    employmentTypeList: [
      {
        icon: "far fa-user-secret",
        type: "Salaried",
      },
      {
        icon: "far fa-user-astronaut",
        type: "Self Employed",
      },
    ],

    model: null,
    documents: [
      { type: "PAN", text: "PAN Card" },
      { type: "Aadhaar", text: "Aadhaar" },
      { type: "Passport", text: "Passport" },
    ],
    selectedDocument: "PAN",
    documentValue: null,
    email: "",
    emailRules: [
      (value) => !!value || "E-mail is required",
      (value) => /.+@.+\..+/.test(value) || "This E-mail is not valid",
    ],
    employer: [
      (value) => !!value || "Employer Name is required.",
      (value) => (value && value.length >= 3) || "Invalid Name",
      (value) => /^[a-zA-Z0-9& ]+$/.test(value) || "Invalid Name",
    ],
    income: [
      (value) => !!value || "Income is required.",
      (value) => /^[1-9,][0-9,]+$/.test(value) || "Invalid Value",
    ],
    firstname: [
      (value) => !!value || "First Name is required.",
      (value) =>
        (value && value.length > 1) || "Your name does not look right!",
      (value) => (value && value.length < 30) || "That is a very long name!",
      (value) => /^[a-zA-Z ]+$/.test(value) || "Nope! please type a valid name",
    ],
    lastname: [
      (value) => !!value || "Last Name is required.",
      // value => (value && value.length > 1) || 'Your name does not look right!',
      (value) => (value && value.length < 30) || "That is a very long name!",
      (value) => /^[a-zA-Z ]+$/.test(value) || "Nope! please type a valid name",
    ],
    docrules: [(value) => !!value || "Document is required."],

    dob: [
      (value) => !!value || "Date of Birth is required.",
      (value) =>
        moment(moment().subtract(365 * 18, "days")).diff(value, "hours") > 0 ||
        "Under Age",
    ],
    mobilenumber: [
      (value) => !!value || "Mobile number is required.",
      (value) => (value && value.length >= 10) || "Invalid mobile number",
      (value) => /^[0-9]*$/.test(value) || "Invalid mobile number",
      // value => (value && value.length >= 3) || 'Min 3 characters',
    ],
    addressOne: [
      (value) => !!value || "Address is required.",
      // value => (value && value.length >= 3) || 'Min 3 characters',
      (value) => (value && value.length >= 2) || "Address too short",
      (value) => (value && value.length <= 40) || "Address too long",
      (value) => /^[#.0-9a-zA-Z \s,-]+$/.test(value) || "Invalid Address",
    ],
    addressTwo: [
      (value) => !!value || "Address is required.",
      // value => (value && value.length >= 3) || 'Min 3 characters',
      (value) => (value && value.length >= 10) || "Address too short",
      (value) => (value && value.length <= 40) || "Address too long",
      (value) => /^[#.0-9a-zA-Z\s,-]+$/.test(value) || "Invalid Address",
    ],

    obj: {
      gender: "Male",
      maritalStatus: "Married",
      firstName: null,
      lastName: null,
      fatherFirstName: null,
      fatherLastName: null,
      applicantType: "Applicant",
      mobileNumber: null,
      emailId: null,
      dateOfBirth: null,
      assetId: null,
      tenure: null,
      marginmoney: null,
    },
    addressLine1: null,
    addressLine2: null,
    employmentType: "Salaried",
    employerName: null,
    annualGrossSalary: null,
    addressType: "RESIDENCE ADDRESS",
    propertyStatus: "R",
    otp: null,
    numberRequired: [],
    formFound: false,
    formToPopulate: "",
    addressOneInput: "",
    addressTwoInput: "",
  }),
  computed: {
    maxDateOfBirth() {
      return moment()
        .subtract(365 * 18, "days")
        .format("YYYY-MM-DD");
    },
    coapplicantRequired() {
      if (
        moment().diff(this.obj.dateOfBirth, "days") > 365 * 18 &&
        moment().diff(this.obj.dateOfBirth, "days") < 365 * 21
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLoading() {
      return this.$store.state.loading_api;
    },
    lastNameRules() {
      return this.obj.firstName !== this.obj.lastName || "Name must be unique";
    },
  },
  watch: {},
  created() {
    if (localStorage.getItem("rawForm")) {
      this.formToPopulate = JSON.parse(localStorage.getItem("rawForm"));
      this.formFound = true;
    }
  },
  props: {
    pincode: {
      type: Object,
    },
    vehicleSelected: {
      type: Object,
    },
  },
  methods: {
    populateData() {
      this.formFound = false;
      this.obj.gender = this.formToPopulate.gender;
      this.obj.maritalStatus = this.formToPopulate.maritalStatus;
      this.obj.firstName = this.formToPopulate.firstName;
      this.obj.lastName = this.formToPopulate.lastName;
      this.obj.emailId = this.formToPopulate.emailId;
      this.obj.mobileNumber = this.formToPopulate.mobileNumber;
      this.obj.lastName = this.formToPopulate.lastName;
      this.obj.dateOfBirth = moment(
        moment(this.formToPopulate.dateOfBirth, "DD/MM/YYYY")
      ).format("YYYY-MM-DD");
      this.addressType = this.formToPopulate.address[0].addressType;
      this.propertyStatus = this.formToPopulate.address[0].propertyStatus;
      this.addressOneInput = this.formToPopulate.addressOne;
      this.addressTwoInput = this.formToPopulate.addressTwo;
      this.employerName = this.formToPopulate.employmentInfo[0].employerName;
      this.employmentType = this.formToPopulate.employmentInfo[0].employmentType;
      this.annualGrossSalary = this.formToPopulate.employmentInfo[0].annualGrossSalary;
      this.selectedDocument = this.formToPopulate.identifier[0].type;
      this.documentValue = this.formToPopulate.identifier[0].value;
    },
    back() {
      this.step--;
      window.scrollTo(0, 0);
    },
    nextForm(refsId, final) {
      this.$gtag.event("form-step-" + refsId, {
        event_category: "click-event",
        event_label: "form submit actions",
        value: 1,
      });
      var check = this.$refs[refsId].validate();
      if (check) {
        if (!final) {
          this.step++;
          window.scrollTo(0, 0);
        } else {
          this.sendOtp();
          // this.saveAndProceed();
        }
      }
    },
    sendOtp() {
      this.otpVerified = false;
      this.$store
        .dispatch("sendOtp", { mobile: this.obj.mobileNumber })
        .then(() => {
          this.otpSent = true;
        })
        .catch(() => {
          this.otpSent = false;
        });
    },
    verifyOTP() {
      this.$store
        .dispatch("verifyOtp", { phone: this.obj.mobileNumber, otp: this.otp })
        .then(() => {
          this.saveAndProceed();
        })
        .catch(() => {
          this.otpVerificationFailed = true;
        });
    },

    saveAndProceed() {
      this.obj.employmentInfo = [
        {
          employmentType: this.employmentType,
          employerName:
            this.employmentType == "Salaried"
              ? this.employerName
              : "selfEmployed",
          annualGrossSalary: Number(this.annualGrossSalary),
        },
      ];
      this.obj.address = [
        {
          addressLine1:
            this.pincode.district + " " + this.pincode.stateData.name,
          addressLine2: this.pincode.cityData.name,
          pincode: this.pincode.pincode.toString(),
          addressType: this.addressType,
          propertyStatus: this.propertyStatus,
          city: this.pincode.cityData.name,
          state: this.pincode.stateData.name,
        },
      ];
      this.obj.identifier = [
        {
          type: this.selectedDocument,
          value: this.documentValue,
        },
      ];
      this.obj.addressOne = this.addressOneInput;
      this.obj.addressTwo = this.addressTwoInput;
      this.obj.assetId = this.vehicleSelected.vehicle.id;
      this.obj.tenure = this.vehicleSelected.tenure;
      this.obj.marginmoney = this.vehicleSelected.marginMoney;
      this.obj.dateOfBirth = moment(this.obj.dateOfBirth).format("DD-MM-YYYY");
      this.obj.source = JSON.parse(localStorage.getItem("source"));
      console.log(this.obj);
      // save this locally
      try {
        localStorage.setItem("rawForm", JSON.stringify(this.obj));
      } catch {
        alert("Some error occured.");
      } finally {
        this.$router.push("confirmdetails");
      }
      // move to final verification
    },
  },
};
</script>
<style scoped></style>
